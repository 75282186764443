/* eslint-disable react/no-array-index-key */
import React from 'react'
import styled from 'styled-components'
import Plaatjie from '@ubo/plaatjie'

// Components
import BlockRounded from 'components/elements/Blocks/BlockRounded'
import ParseContent from 'components/shared/ParseContent'

const Section = styled.section`
  background-color: rgba(214, 225, 253, 0.24);
  color: ${({ theme }) => theme.color.primary};
`

const Certificate = styled(BlockRounded)`
  background-color: ${({ theme }) => theme.color.light};

  @media (min-width: 768px) {
    width: 195px;
    height: 195px;
  }

  @media (max-width: 767px) {
    width: 115px;
    height: 115px;
  } ;
`

const Icon = styled(Plaatjie)`
  max-height: 131px;

  & img {
    object-fit: contain !important;
  }

  @media (min-width: 768px) {
    width: 166px;
  }

  @media (max-width: 767px) {
    width: 122px;
    max-height: 75px;
  }
`

interface CertificatesProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Certificates
}

const Certificates = ({ fields }: CertificatesProps) => (
  <Section className="py-5">
    <div className="container pb-5">
      <div className="row justify-content-center">
        <div className="col-lg-9">
          <ParseContent content={fields.description} className="mb-5" />
          <div className="row justify-content-center">
            {fields.certificates?.map((certificate, index) => (
              <div
                className="col-6 col-sm-4 mb-4 d-flex justify-content-center"
                key={index}
              >
                <Certificate className="d-flex justify-content-center align-items-center rounded-circle">
                  <Icon image={certificate?.icon} alt="" />
                </Certificate>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  </Section>
)

export default Certificates
