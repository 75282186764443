import React, { ReactNode } from 'react'
import styled, { css } from 'styled-components'

const Wrapper = styled.div<{ light?: boolean; gradient?: boolean }>`
  ${(props) =>
    props.light &&
    css`
      background-color: ${({ theme }) => theme.color.light};
      box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15);
    `}

  ${(props) =>
    props.gradient &&
    css`
      background: linear-gradient(
        270deg,
        rgba(99, 110, 255, 0) 0%,
        #636eff 48.02%
      );
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    `}

  @media (min-width: 576px) {
    border-radius: 150px;
  }

  @media (max-width: 575px) {
    border-radius: 50px;
  }
`

interface BlockRoundedProps {
  children: ReactNode
  gradient?: boolean
  light?: boolean
  className?: string
  style?: Object
}

const BlockRounded = ({
  light,
  gradient,
  children,
  className,
  style,
}: BlockRoundedProps) => (
  <Wrapper
    light={light}
    gradient={gradient}
    className={className}
    style={style}
  >
    {children}
  </Wrapper>
)

export default BlockRounded
